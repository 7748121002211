// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Marquee from "react-fast-marquee";

// Images
import milaLogoGrey from "assets/images/logos/gray-logos/logo-grey-mila.svg";
import uoftLogoGrey from "assets/images/logos/gray-logos/logo-grey-uoft.svg";
import cambridgeLogoGrey from "assets/images/logos/gray-logos/logo-grey-cambridge.svg";
import baycrestLogoGrey from "assets/images/logos/gray-logos/logo-grey-baycrest.svg";
import mcgillLogoGrey from "assets/images/logos/gray-logos/logo-grey-mcgill.svg";
import uhnLogoGrey from "assets/images/logos/gray-logos/logo-grey-uhn.svg";
import yorkLogoGrey from "assets/images/logos/gray-logos/logo-grey-york.svg";

function UsedBy() {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Marquee gradient={false} speed={25}>
          <a
            href="https://mila.quebec/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              src={milaLogoGrey}
              alt="Mila"
              width="100%"
              breakpoints={{
                995: { width: "50%" },
                1000: { width: "50%" },
              }}
              opacity={0.6}
            />
          </a>
          <a
            href="https://www.utoronto.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              src={uoftLogoGrey}
              alt="University of Toronto"
              breakpoints={{
                995: { width: "50%" },
                1000: { width: "50%" },
              }}
              width="100%"
              opacity={0.6}
            />
          </a>
          <a
            href="https://www.baycrest.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              src={baycrestLogoGrey}
              alt="Baycrest"
              width="100%"
              opacity={0.6}
            />
          </a>
          <a
            href="https://www.cam.ac.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              src={cambridgeLogoGrey}
              alt="University of Cambridge"
              width="100%"
              opacity={0.6}
            />
          </a>
          <a
            href="https://www.mcgill.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              src={mcgillLogoGrey}
              alt="McGill University"
              width="100%"
              opacity={0.6}
            />
          </a>
          <a
            href="https://www.uhn.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              src={uhnLogoGrey}
              alt="Uiversity Health Network"
              width="100%"
              opacity={0.6}
            />
          </a>
          <a
            href="https://www.yorku.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              src={yorkLogoGrey}
              alt="York University"
              width="100%"
              opacity={0.6}
            />
          </a>
        </Marquee>
      </Container>
    </MKBox>
  );
}

export default UsedBy;
