// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";
import StudyFeatureCard from "pages/FeaturedStudies/components/cards/StudyFeature";

// Images
import study1Pic from "assets/images/Experimenter/Featured/study1Pic.png";
import study2Pic from "assets/images/Experimenter/Featured/study2Pic.png";
import study3Pic from "assets/images/Experimenter/Featured/study3Pic.png";

function FeaturedStudies() {
  const actionProps = {
    type: "internal",
    route: "/featured-studies",
    color: "info",
    label: "Learn More",
  };

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <StudyFeatureCard
                image={study1Pic}
                title="Augmented Reality + mEEG"
                description="Researchers at Cambridge University are using Experimenter to create augmented reality tasks using the Hololens 2 while capturing realtime brain activity using mEEG"
                categories={["", "Cambridge University, UK"]}
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <StudyFeatureCard
                image={study2Pic}
                title="Single room in the center of the city"
                description="As Uber works through a huge amount of internal management turmoil, the company is also consolidating more of its international business."
                categories={["", "Mcgill Univeristy/Mila, CA"]}
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <StudyFeatureCard
                image={study3Pic}
                title="Baseball Fielder"
                description="Music is something that every person has his or her own specific opinion about. Different people have different taste, and various types of music."
                categories={["", "University of Toronto, CA"]}
                action={actionProps}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FeaturedStudies;
