/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";

// Otis Kit PRO components
import TaskExampleCard from "pages/TaskExamples/components/cards/TaskExample";

// Images
import study1Pic from "assets/images/Experimenter/Examples/study4Pic.png";
import study2Pic from "assets/images/Experimenter/Examples/study2Pic.png";
import study3Pic from "assets/images/Experimenter/Examples/study3Pic.png";

function Examples() {
  const actionProps = {
    type: "internal",
    route: "/pages/landing-pages/rental",
    color: "info",
    label: "from / night",
  };

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <TaskExampleCard
                image={study1Pic}
                title="Object Norming"
                description="That's like comparing apples to t-shirts!' This task was designed to get appeal ratings for the Dino Image Bank - an image set used for psychology research."
                categories={["2D", "Norming"]}
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <TaskExampleCard
                image={study2Pic}
                title="Grid World"
                description="Are you smarter than a 5-layer convolutional network? This task is designed test machine learning models, but we'll let you give it a go!"
                categories={["3D", "Machine Learning"]}
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <TaskExampleCard
                image={study3Pic}
                title="Baseball Fielder"
                description="The scouts are watching, show them that you're MLB material! This task is designed to test your memory - see how well you can predict where a batter is going to hit to next!"
                categories={["2D", "3D"]}
                action={actionProps}
              />
            </MKBox>
          </Grid>
        </Grid>
        <MKBox mt={5}>
          <MKPagination>
            <MKPagination item>
              <Icon>keyboard_arrow_left</Icon>
            </MKPagination>
            <MKPagination item active>
              1
            </MKPagination>
            <MKPagination item>2</MKPagination>
            <MKPagination item>3</MKPagination>
            <MKPagination item>4</MKPagination>
            <MKPagination item>5</MKPagination>
            <MKPagination item>
              <Icon>keyboard_arrow_right</Icon>
            </MKPagination>
          </MKPagination>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Examples;
