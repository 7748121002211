/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Otis Kit PRO components
import MKBox from "components/MKBox";

// Otis Kit PRO examples
import DefaultNavbar from "components/NavBar";
import DefaultFooter from "components/Footers/DefaultFooter";
import Pricing from "pages/ExperimenterPricing/sections/Pricing";

// Routes
import routes from "pages/Experimenter/routes";
import footerRoutes from "footer.routes";

// Images

function ExperimenterPricing() {
  return (
    <>
      <DefaultNavbar
        brand="Experimenter - Academic Pricing"
        routes={routes}
        action={{
          type: "external",
          route: "https://material-ui.com/store/items/otis-kit-pro-material-kit-react/",
          label: "Sign in",
          color: "info",
        }}
        sticky
      />
      <Pricing />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ExperimenterPricing;
