// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKSnackbar from "components/MKSnackbar";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import { useState } from "react";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import researcher from "assets/images/SilicoLabs/Solutions/researcher.svg";

function InfoResearcher() {
  const [show, setShow] = useState(false);
  const toggleSnackbar = () => setShow(!show);

  return (
    <>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        shadow="xxl"
        width="100%"
        mt={5}
        mb={5}
        py={0}
        px={-3}
        // sx={{ overflow: "hidden" }}
      >
        <MKBox
          component="img"
          src={bgPattern}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          opacity={0.6}
          shadow="xxl"
        />
        <Container sx={{ position: "relative" }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              xs={12}
              position="absolute"
              left="50%"
              ml={10}
              width="55%"
            >
              <MKBox
                component="img"
                src={researcher}
                alt="macbook"
                width="75%"
                display={{ xs: "none", md: "block" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              lg={5}
              py={{ xs: 0, sm: 6 }}
              mr="auto"
              position="relative"
              mb={2}
            >
              <MKTypography variant="h2" color="white" mb={1} mt={2}>
                Researcher
              </MKTypography>
              <MKTypography variant="body1" color="white" mb={6}>
                Tools are only as good as the resouces and community that
                supports them. That's why we created
                <b>
                  <i> Researcher</i>
                </b>
                , a platform for sharing design materials, tasks, and data.
              </MKTypography>
              <MKButton
                variant="gradient"
                buttonColor="info"
                onClick={toggleSnackbar}
              >
                Learn More
              </MKButton>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKSnackbar
        color="info"
        icon="notifications"
        title="Material Dashboard"
        content="Hello, world! This is a notification message"
        dateTime="11 mins ago"
        open={show}
        close={toggleSnackbar}
      />
    </>
  );
}

export default InfoResearcher;
