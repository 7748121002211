// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import PanelInfo from "pages/Participant/components/subsections/panelinfo";

import recruit_online from "assets/images/Participant/recruit/recruit_online.jpg";
import recruit_community from "assets/images/Participant/recruit/recruit_community.jpg";
import recruit_participant from "assets/images/Participant/recruit/recruit_participant.jpg";

function PanelFlexibility() {
  return (
    <Card>
      <MKBox
        component="section"
        py={{ xs: 3, md: 12, lg: 2 }}
        // px={{ xs: 0, md: 12, lg: 10 }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12} lg={4} justifyContent="right" mx="auto">
              <MKTypography variant="h3" my={5} color="#E37174">
                Participant Recruitment
              </MKTypography>
              <MKTypography variant="body2" color="#E37174" mb={2}>
                Flexible options provide full control over the participant
                recruitment
              </MKTypography>
              <MKTypography
                component="a"
                href="#"
                variant="body2"
                color="#E37174"
                fontWeight="regular"
                sx={{
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: "translateX(3px)",
                    transition:
                      "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                  },

                  "&:hover .material-icons-round, &:focus .material-icons-round":
                    {
                      transform: "translateX(6px)",
                    },
                }}
              >
                Get Started
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </MKTypography>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}
            >
              <Stack>
                <PanelInfo
                  icon={
                    <MKBox
                      component="img"
                      src={recruit_online}
                      alt="recruit_online"
                      width={100}
                      height={100}
                    />
                  }
                  content={
                    <>
                      <b>Online Platforms</b>
                      <br />
                      Recruit online using the platform of your
                      <br />
                      choosing (e.g., Prolific, MTurk).
                    </>
                  }
                />
                <PanelInfo
                  icon={
                    <MKBox
                      component="img"
                      src={recruit_community}
                      alt="experimenter"
                      width={100}
                      height={100}
                    />
                  }
                  content={
                    <>
                      <b>Community</b>
                      <br />
                      Recruit from your own community
                      <br />
                      participant pools.
                    </>
                  }
                />
                <PanelInfo
                  icon={
                    <MKBox
                      component="img"
                      src={recruit_participant}
                      alt="experimenter"
                      width={100}
                      height={100}
                    />
                  }
                  content={
                    <>
                      <b>Participant Users</b>
                      <br />
                      Let us take care of recruitment, by
                      <br />
                      utilizing users of the Participant app.
                    </>
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </Card>
  );
}

export default PanelFlexibility;
