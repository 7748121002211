// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

import Participant from "layouts/pages/participant";
import Experimenter from "layouts/pages/experimenter";
import FAQ from "layouts/pages/faq";
import ContactUs from "layouts/pages/contact";
import Timeline from "layouts/pages/timeline";
import FeaturedStudiesPage from "layouts/pages/featured-studies";
import News from "layouts/pages/news";

// Otis Kit PRO components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/Footer/researcher.svg";

const date = new Date().getFullYear();

const footer = {
  brand: {
    name: "SilicoLabs",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/SilicoLabs",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/SilicoLabs",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCH3VYZcQ5iWgjYOtxr4SB2g",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/silicolabs",
    },
  ],
  menus: [
    {
      name: "Solutions",
      items: [
        {
          name: "Experimenter",
          route: "/experimenter",
          component: <Experimenter />,
        },
        {
          name: "Participant",
          route: "/participant",
          component: <Participant />,
        },
        {
          name: "Researcher",
          href: "/",
        },
      ],
    },
    {
      name: "Learn More",
      items: [
        // {
        //   name: "Timeline",
        //   route: "/timeline",
        //   component: <Timeline />,
        // },
        {
          name: "News & Updates",
          route: "/news",
          component: <News />,
        },
        // {
        //   name: "Case Studies",
        //   route: "/experimenter-examples",
        //   component: <FeaturedStudiesPage />,
        // },
      ],
    },
    {
      name: "help & support",
      items: [
        {
          name: "contact us",
          route: "/contact",
          component: <ContactUs />,
        },
        // {
        //   name: "FAQ",
        //   route: "/faq",
        //   component: <FAQ />,
        // },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} SilicoLabs.
    </MKTypography>
  ),
};

export default footer;
