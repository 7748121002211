/**
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Otis Kit PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

const routes = [
  {
    name: "Features",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Design Framework",
        description: "Stop staring at lines of code!",
        href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/quick-start/otis-kit/",
      },
      {
        name: "Cross Platform Compatibility",
        description: "Desktop, Mobile, and XR",
        href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/colors/otis-kit/",
      },
      {
        name: "External Device Support",
        description: "Turn on, boot Up, jack In!",
        href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/colors/otis-kit/",
      },
      {
        name: "Artificial Intelligence",
        description: "Not just for the sentient!",
        href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/colors/otis-kit/",
      },
    ],
  },
  {
    name: "Learn More",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Task Examples",
        description: "See what experimenter can do!",
        href: "/experimenter-examples",
      },
      {
        name: "Featured Studies",
        description: "Projects from around the globe",
        href: "/featured-studies",
      },
      {
        name: "Frequently Asked Questions",
        description: "Got a question?",
        href: "/faq",
      },
    ],
  },
  {
    name: "Pricing",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Academic",
        description: "Subscriptions built for #LabLife",
        href: "/experimenter-pricing",
      },
      {
        name: "Enterprise",
        description: "Put science to work for you!",
        href: "/experimenter-pricing",
      },
      {
        name: "Consulting & Collaborations",
        description: "Let us help bring your idea To life!",
        href: "/experimenter-pricing",
      },
    ],
  },
];

export default routes;
