// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// components
import MKBox from "components/MKBox";
import RotatingCard from "pages/SilicoLabs/components/cards/Rotating";
import RotatingCardFront from "pages/SilicoLabs/components/cards/Rotating/RotatingCardFront";
import RotatingCardBack from "pages/SilicoLabs/components/cards/Rotating/RotatingCardBack";
import FeatureCard from "pages/SilicoLabs/components/cards/Feature";

// Images
import bgFront from "assets/images/SilicoLabs/Solutions/participantCardFront.jpg";
import bgBack from "assets/images/SilicoLabs/Solutions/participantCardBack.jpg";
import participantIcon from "assets/images/SilicoLabs/Solutions/participant.svg";
import platformIcon from "assets/images/SilicoLabs/Solutions/icon-platform.svg";
import panelIcon from "assets/images/SilicoLabs/Solutions/icon-panel.svg";
import vrIcon from "assets/images/SilicoLabs/Solutions/icon-vr.svg";
import integration from "assets/images/SilicoLabs/Solutions/icon-integration.svg";

function InfoPariticpant() {
  return (
    <MKBox component="section" py={0} my={6}>
      <Container>
        <Grid
          container
          item
          xs={11}
          spacing={3}
          alignItems="center"
          sx={{ mx: "auto" }}
        >
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FeatureCard
                  icon={
                    <img
                      src={platformIcon}
                      alt="experimenter"
                      height={50}
                      width={50}
                    />
                  }
                  title="Cross-Platform"
                  description="Easily export any task to desktop or mobile devices."
                  textColor="textRed"
                  titleTextColor="textRed"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FeatureCard
                  icon={
                    <img
                      src={panelIcon}
                      alt="experimenter"
                      height={50}
                      width={50}
                    />
                  }
                  title="Flexible Recruitment"
                  description="Recruit yourself, use online recruiting platforms, or let us handle it!"
                  textColor="textRed"
                  titleTextColor="textRed"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <FeatureCard
                  icon={
                    <img
                      src={integration}
                      alt="experimenter"
                      height={50}
                      width={50}
                    />
                  }
                  title="Experimenter Integration"
                  description="Tap into Experimenters platform specific features."
                  textColor="textRed"
                  titleTextColor="textRed"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FeatureCard
                  icon={
                    <img
                      src={vrIcon}
                      alt="experimenter"
                      height={50}
                      width={50}
                    />
                  }
                  title="Immersive"
                  description="Create immersive experiences by exporting your tasks to virtual and augmented reality headsets."
                  textColor="textRed"
                  titleTextColor="textRed"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon={
                  <img
                    src={participantIcon}
                    alt="experimenter"
                    height={150}
                    width={150}
                  />
                }
                title={<>Participant</>}
                color="silicoRed"
                description="Unlock a whole new world of testing posibilities"
              />
              <RotatingCardBack
                image={bgBack}
                title="Start Testing!"
                description="Anywhere, anytime, on any platform"
                color="silicoRed"
                action={{
                  type: "internal",
                  route: "/participant",
                  label: "Learn More",
                }}
              />
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default InfoPariticpant;
