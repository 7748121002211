// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import { useState } from "react";

import React, { useRef } from "react";
// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import waitListImage from "assets/images/Auxiliary/WaitList.jpg";

import DefaultNavbar from "components/NavBar";
import DefaultFooter from "components/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";
import reactSyntaxHighlighter from "react-syntax-highlighter";

import emailjs from "@emailjs/browser";

function WaitList() {
  const form = useRef();

  function handleSubmit() {
    emailjs
      .sendForm(
        "service_wgjj9w2",
        "template_9vp5a9q",
        form.current,
        "s6Oohvr3zW5ngVuJ8"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <>
      <DefaultNavbar brand="SilicoLabs / Wait List" routes={routes} sticky />
      <MKBox
        component="section"
        display="grid"
        position="relative"
        minHeight="90vh"
        borderRadius="xl"
        mr={{ xs: 0, lg: -2 }}
        mb={{ xs: 0, lg: -2 }}
        sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
      >
        <MKBox
          component="img"
          src={waitListImage}
          alt="lake house"
          width={{ xs: "100%", lg: "50%" }}
          height={{ xs: "100%", lg: "100%" }}
          position="absolute"
          right={0}
          bottom={{ xs: "-25%", lg: "unset" }}
          top={{ xs: 0, lg: "unset" }}
          sx={{
            objectFit: "cover",
            borderTopLeftRadius: ({ borders: { borderRadius } }) => ({
              xs: 0,
              lg: borderRadius.lg,
            }),
          }}
        />
        <Container>
          <Grid
            container
            spacing={{ xs: 0, lg: 3 }}
            sx={{ mt: { xs: 0, lg: 12 } }}
          >
            <Grid
              item
              xs={12}
              lg={7}
              justifyContent="center"
              flexDirection="column"
            >
              <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                shadow="lg"
                borderRadius="xl"
                sx={{
                  backgroundColor: ({
                    functions: { rgba },
                    palette: { white },
                  }) => rgba(white.main, 0.8),
                  backdropFilter: "saturate(200%) blur(30px)",
                  px: { xs: 3, sm: 6 },
                  py: { xs: 3, sm: 8 },
                  mb: { xs: 0, lg: 0 },
                  mt: { xs: 0, lg: -6 },
                }}
              >
                <MKTypography variant="h2" textAlign="center" mb={1}>
                  Join the Wait List!
                </MKTypography>
                <MKTypography variant="body2" textAlign="center" mb={2} mx={6}>
                  We want you to have an excellent experience. That's why we're
                  providing access to our tools on a rolling basis. This ensures
                  that our small team can provide the highest quality of
                  support!
                </MKTypography>
                <form ref={form}>
                  <MKBox py={3} px={{ xs: 3, md: 12, lg: 6, xl: 12 }}>
                    <Grid container spacing={3} sx={{ mb: 3 }}>
                      <Grid item xs={12} md={6} lg={12} xl={6}>
                        <MKInput
                          name="first_name"
                          variant="standard"
                          placeholder="eg. Jane"
                          label="First Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={12} xl={6}>
                        <MKInput
                          name="last_name"
                          variant="standard"
                          placeholder="eg. Doe"
                          label="Last Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ my: 1 }}>
                        <MKInput
                          name="email"
                          variant="standard"
                          type="email"
                          placeholder="eg. researcher@science.com"
                          label="Email Address"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          name="message"
                          variant="standard"
                          label="Describe your Use Case (Optional)"
                          rows={4}
                          InputLabelProps={{ shrink: true }}
                          multiline
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      {/* <Grid item xs={12} sx={{ mb: 3, ml: -1 }}>
                        <Switch id="flexSwitchCheckDefault" defaultChecked />
                        <MKTypography
                          component="label"
                          variant="button"
                          color="text"
                          fontWeight="regular"
                          htmlFor="flexSwitchCheckDefault"
                          sx={{ userSelect: "none", cursor: "pointer" }}
                        >
                          I agree to the{" "}
                          <MKTypography
                            component="a"
                            href="#"
                            variant="button"
                            fontWeight="regular"
                          >
                            <u>Terms and Conditions</u>
                          </MKTypography>
                          .
                        </MKTypography>
                      </Grid> */}
                      <Grid item xs={12}>
                        <MKButton
                          type="submit"
                          variant="gradient"
                          color="dark"
                          fullWidth
                          onClick={() => handleSubmit()}
                        >
                          Send Message
                        </MKButton>
                      </Grid>
                    </Grid>
                  </MKBox>
                </form>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default WaitList;
