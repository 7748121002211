// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

// Otis Kit PRO components
import ExecutiveMemberCard from "pages/SilicoLabs/components/cards/Executive";

// Images
import bgImage from "assets/images/SilicoLabs/Team/background.jpg";
import benjamin from "assets/images/SilicoLabs/Team/benjamin.jpg";
import kyla from "assets/images/SilicoLabs/Team/kyla.jpg";
import eli from "assets/images/SilicoLabs/Team/eli.jpg";
import etienne from "assets/images/SilicoLabs/Team/etienne.jpg";

function Executive() {
  return (
    <Container alignItems="center">
      <Grid
        container
        spacing={3}
        sx={{ mt: 10, mb: 6 }}
        alignItems="center"
        justifyContent="center"
      >
          <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
          <ExecutiveMemberCard
            image={benjamin}
            name="Benjamin Alsbury-Nealy"
            username="Founder, Chief Executive Officer"
            scholar="https://scholar.google.com/citations?user=gnHRSVkAAAAJ&hl=en"
            linkedin="https://www.linkedin.com/in/benjamin-alsbury-nealy-a1855344/"
            twitter="https://twitter.com/B_AlsburyNealy"
            review="PhD Candidate in Cognitive Neuroscience, with 6+ years experience in XR and human-centered A.I. R&D"
          />
        </Grid>

          <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
          <ExecutiveMemberCard
            image={kyla}
            scholar="https://scholar.google.com/citations?hl=en&user=uIJrtr4AAAAJ"
            linkedin="https://www.linkedin.com/in/kyla-alsbury-nealy-20726ba4/"
            twitter="https://twitter.com/kyalsburynealy"
            name="Kyla Alsbury-Nealy, PhD"
            username="Founder, Chief Operating Officer"
            review="PhD in Rehab Science & Registered Physiotherapist with 6+ years experience in healthcare systems research"
          />
        </Grid>
          <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
              <ExecutiveMemberCard
                  image={eli}
                  linkedin="https://www.linkedin.com/in/eli-samuel/"
                  name="Eli Samuel"
                  username="Lead Engineer, Research and Development"
                  review="Concordia/Mila graduate in Computer Science specializing in 3D generative A.I."
              />
          </Grid>
          <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 8 } }}>
          <ExecutiveMemberCard
              image={etienne}
              linkedin="https://www.linkedin.com/in/etienne-barou-laforie-97b517220/"
              name="Etienne Barou-Laforie"
              username="Lead Engineer, Research and Development"
              review="McGill/Mila graduate in Cognitive Science specializing in neuroscience-inspired A.I."
          />
      </Grid>
      </Grid>
    </Container>
  );
}

export default Executive;
