import { useRef, useState } from "react";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";
import MKAvatar from "components/MKAvatar";

// SwiperJS react components
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";
import Stack from "@mui/material/Stack";

import "swiper/css";
import "swiper/css/navigation";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import study_training from "assets/images/Experimenter/FeaturedTasks/study_training.png";
import study_twin from "assets/images/Experimenter/FeaturedTasks/study_twin.png";
import study_simulate from "assets/images/Experimenter/FeaturedTasks/study_simulate.png";
import study_market from "assets/images/Experimenter/FeaturedTasks/study_market.png";

import study_AR from "assets/images/Experimenter/FeaturedTasks/study_AR.png";
import study_AI from "assets/images/Experimenter/FeaturedTasks/study_AI.png";
import study_aero from "assets/images/Experimenter/FeaturedTasks/study_aero.png";

import mcgill from "assets/images/Experimenter/FeaturedTasks/mcgill-large.png";
import cambridge from "assets/images/Experimenter/FeaturedTasks/cambridge-large.png";
import uoft from "assets/images/Experimenter/FeaturedTasks/uoft-large.png";
import baycrest from "assets/images/Experimenter/FeaturedTasks/baycrest-large.png";
import uhn from "assets/images/Experimenter/FeaturedTasks/uhn-large.png";
import aero from "assets/images/Experimenter/FeaturedTasks/aero-large.png";
import market from "assets/images/Experimenter/FeaturedTasks/market-large.png";

function TaskRotation() {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [swiperEl, setSwiperEl] = useState(null);

  const slideTo = (index) => swiperEl && swiperEl.slideTo(index);

  const slides = [
    {
      image: study_twin,
      logo: baycrest,
      label: "The Rotman Research Institute",
      title: "Digital Twins",
      description:
        "Researchers at the Rotman Research Institute have used our software to create tasks within digital replicas of real-world environments.",
    },
    {
      image: study_simulate,
      logo: uoft,
      label: "University of Toronto",
      title: "3D Simulations",
      description:
        "Researchers at the University of Toronto are using our software to run 3D environment tasks online, a feature that has allowed them to continue their research during the COVID-19 pandemic.",
    },
    {
      image: study_AI,
      logo: mcgill,
      label: "McGill Univeristy | Mila",
      title: "Developing AI",
      description:
        "Researchers at McGill University and Mila are using our software to train and test artificial intelligence models in 3D environments. Models are given visual data and learn to make complex decisions.",
    },
    {
      image: study_AR,
      logo: cambridge,
      label: "Cambridge Univeristy",
      title: "VR/AR/XR",
      description:
        "Using our software, researchers at Cambridge University place virtual objects around campus and record brain data to investigate cognition in the real world.",
    },
    {
      image: study_training,
      logo: uhn,
      label: "UHN | York University",
      title: "Health Care",
      description:
        "We're working with UHN and York University to develop virtual training programs for healthcare workers.",
    },
    {
      image: study_aero,
      logo: aero,
      label: "cifal | ON AERO",
      title: "Virtual Training",
      description:
        "We're working with Ontario Airlift Emergency Response Operations and cifal at York University to develop training for emergency response volunteers.",
    },
    {
      image: study_market,
      logo: market,
      label: "Product Testing",
      title: "Market Research",
      description:
        "We're working with market research firms to create mini experiences that can be combine with surveys to gain deeper insights into target market behaviour.",
    },
  ];

  // Swiper navigation buttons styles
  const navigationStyles = {
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  return (
    <MKBox component="section" py={2} position="relative">
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={0}
        >
          <MKTypography variant="h1" mb={1}>
            Built For...
          </MKTypography>
          <Grid container spacing={2} justifyContent="center">
            <MKTypography variant="body1" color="text" mb={2}>
              Our flexible framework accommodates a wide range of use cases
            </MKTypography>
          </Grid>
        </Grid>
        <Swiper
          onInit={(swiper) => {
            setSwiperEl(swiper);

            const { navigation: nav } = swiper.params;
            const { navigation } = swiper;

            nav.prevEl = navigationPrevRef.current;
            nav.nextEl = navigationNextRef.current;
            navigation.init();
            navigation.update();
          }}
          autoplay={{ delay: 5000 }}
          speed={800}
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove={false}
          loop
        >
          {slides.map(({ image, label, title, description, logo }) => (
            <SwiperSlide key={label}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ mb: { xs: 6, md: 0 } }}
              >
                <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
                  <MKBox p={2}>
                    <MKBox
                      component="img"
                      src={image}
                      alt={title}
                      width="100%"
                      borderRadius="xl"
                      maxHeight="37.5rem"
                    />
                  </MKBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  mr={{ xs: 0, lg: "auto" }}
                  position="relative"
                >
                  <MKTypography
                    variant="h3"
                    fontWeight="bold"
                    sx={{
                      fontSize: ({ typography: { d5, d4 } }) => ({
                        xs: d4.fontSize,
                        lg: d5.fontSize,
                      }),
                    }}
                  >
                    {title}
                  </MKTypography>
                  <MKTypography variant="body1" my={1}>
                    {description}
                  </MKTypography>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="left"
                    alignItems="center"
                  >
                    <MKBox component="img" src={logo} alt={title} width="10%" />

                    <MKTypography
                      component="h6"
                      variant="button"
                      //   verticalAlign="middle"
                      opacity={0.7}
                      textTransform="uppercase"
                      fontWeight="bold"
                    >
                      {label}
                    </MKTypography>
                  </Stack>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
          <MKBox
            display="flex"
            position="absolute"
            bottom={{ xs: 0, lg: "10%" }}
            right="5%"
            zIndex={2}
          >
            <MKTypography
              variant="h2"
              ref={navigationPrevRef}
              color="dark"
              mr={{ xs: 8, md: 2, lg: 8 }}
              sx={navigationStyles}
            >
              <MKBox className="fas fa-chevron-left" />
            </MKTypography>
            <MKTypography
              variant="h2"
              ref={navigationNextRef}
              color="dark"
              sx={navigationStyles}
            >
              <MKBox className="fas fa-chevron-right" />
            </MKTypography>
          </MKBox>
        </Swiper>
      </Container>
    </MKBox>
  );
}

export default TaskRotation;
