// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

// Otis Kit PRO components
import ExecutiveMemberCard from "pages/SilicoLabs/components/cards/Executive";

// Images
import bgImage from "assets/images/SilicoLabs/Team/background.jpg";
import blake from "assets/images/SilicoLabs/Team/blake.jpg";
import emile from "assets/images/SilicoLabs/Team/emile.jpg";
import alex from "assets/images/SilicoLabs/Team/alex.jpg";
import john from "assets/images/SilicoLabs/Team/john.jpg";
import ned from "assets/images/SilicoLabs/Team/ned.jpg";
import victoria from "assets/images/SilicoLabs/Team/victoria.jpg";
import david from "assets/images/SilicoLabs/Team/david.jpg";

function Advisory() {
  return (
    <Container alignItems="center">
      <Grid
        container
        spacing={3}
        sx={{ mt: 10, mb: 6 }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
          <ExecutiveMemberCard
            image={blake}
            name="Blake Richards, PhD"
            scholar="https://scholar.google.com/citations?user=1CPY1LsAAAAJ&hl=ja"
            website="https://linclab.mila.quebec/"
            twitter="https://twitter.com/tyrell_turing"
            username="Artificial Intelligence Expert"
            review="Blake Richards is an Assistant Professor at McGill University and a Core Faculty Member at Mila"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
          <ExecutiveMemberCard
            image={john}
            name="John Wilkinson"
            linkedin="https://www.linkedin.com/in/john-wilkinson-821b274b/"
            username="Corporate Governance Expert"
            review="John is the former Ontario Minister of Research and Innovation"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 0 } }}>
          <ExecutiveMemberCard
            image={ned}
            name="Ned Welch, PhD"
            scholar="https://scholar.google.com/citations?hl=en&user=H_XhLiEAAAAJ"
            linkedin="https://www.linkedin.com/in/ned-welch-88895b/"
            username="Enterprise Research Expert"
            review="Ned is an Adjunct Professor and Executive in Residence at Rotman School of Management"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 8 } }}>
          <ExecutiveMemberCard
            image={victoria}
            name="Victoria Birkett"
            website="https://www.innovationgrade.com/"
            username="Startup Scaling & Investment Expert"
            review="Entrepreneur with over 30 years experience in brand marketing, healthcare and venture funding"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 8 } }}>
          <ExecutiveMemberCard
            image={david}
            name="David Ceolin"
            website="https://www.innovationgrade.com/"
            linkedin="https://www.linkedin.com/in/davidceolin/"
            username="Startup Scaling & Investment Expert"
            review="Successful entrepreneur, best-selling author, and venture investor"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 8 } }}>
          <ExecutiveMemberCard
            image={alex}
            name="Alex Clarke, PhD"
            scholar="https://scholar.google.ca/citations?user=-LWrKxUAAAAJ&hl=en"
            twitter="https://twitter.com/AlexDavidClarke"
            username="Immersive Technologies Research Expert"
            review="Alex is a Senior Research Associate and Sir Henry Dale Fellow at Cambridge University"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mt: { xs: 12, md: 8 } }}>
          <ExecutiveMemberCard
            image={emile}
            name="Emile McLean"
            linkedin="https://www.linkedin.com/in/emilemclean/"
            username="Business Development Expert"
            review="Executive and entrepreneur with over 20 years experience in marketing, technology and research"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Advisory;
