/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Divider from "@mui/material/Divider";

import taskdesign from "assets/images/Experimenter/taskdesign.png";
import taskdesign_bg from "assets/images/Experimenter/taskdesign_bg.png";

import ListItem from "pages/Experimenter/components/ListItem";

import RaisedBlogCard from "components/Cards/BlogCards/RaisedBlogCard";

function TaskDesign() {
  return (
    <MKBox
      display="flex"
      alignItems="center"
      my={2}
      mx={-2}
      py={6}
      sx={{
        backgroundColor: "#212529",
      }}
    >
      <Container alignItems="center">
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={6}
        >
          <MKTypography variant="h1" mb={1} color="white">
            Flexible Task Design
          </MKTypography>
          <MKTypography color="white">
            2D, 3D, and anything in between
          </MKTypography>
        </Grid>
        <MKBox
          component="img"
          src={taskdesign}
          alt="macbook"
          width="100%"
          display={{ xs: "block", md: "block" }}
        />
        {/* <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            position="left"
            // left="42%"
            // mr={-32}
            width="60%"
          >
            <MKBox
              component="img"
              src={taskdesign}
              alt="macbook"
              width="100%"
              display={{ xs: "none", md: "block" }}
            />
          </Grid>
          <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
            <MKTypography variant="h1" color="white" mb={1}>
              2D, 3D, and anything in between
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
              From colors, cards, typography to complex elements, you will find
              the full documentation. Play with the utility classes and you will
              create unlimited combinations for our components.
            </MKTypography>
          </Grid>
        </Grid> */}
      </Container>
    </MKBox>
  );
}

export default TaskDesign;
