// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import laptop from "assets/images/Experimenter/nocode-ui.png";

function Realtime() {
  return (
    <MKBox
      //   variant="gradient"
      bgColor="#5BADDC"
      position="relative"
      borderRadius="xl"
      mx={{ xs: 0, xl: 3, xxl: 16 }}
      mt={0}
      py={13}
      px={{ xs: 0, lg: 3 }}
      sx={{ overflow: "hidden" }}
    >
      <MKBox
        component="img"
        src={bgPattern}
        alt="pattern-lines"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.6}
      />
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={5} sx={{ ml: { xs: "relative", lg: "auto" } }}>
            <MKTypography variant="h2" color="white" mb={1}>
              An intuitive no-code design that inspires creativity
            </MKTypography>
            <MKTypography variant="body1" color="white" mb={6}>
              Visualize and make changes to every aspect of your Experience in
              real-time from the players perspective.
            </MKTypography>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            lg={5}
            sx={{ mr: { xs: "auto", lg: "auto" } }}
          >
            <MKBox
              component="img"
              src={laptop}
              alt="macbook"
              width={{ xs: "100%", lg: "130%" }}
              display={{ xs: "block", md: "block" }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Realtime;
