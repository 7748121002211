// components
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// NavBar
import DefaultNavbar from "components/NavBar";

// Sections
import FeaturedStudies from "pages/FeaturedStudies/sections/FeaturedStudies";

// Footer
import DefaultFooter from "components/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import heroImage from "assets/images/Experimenter/Featured/hero.png";

function Studies() {
  return (
    <>
      <DefaultNavbar
        brand="SilicoLabs / Featured Studies"
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={8} justifyContent="right" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              textAlign="left"
              mt={-6}
              mb={1}
              mr={-10}
              width="70%"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
              })}
            >
              Featured Studies
            </MKTypography>
            <MKTypography
              width="60%"
              variant="body1"
              color="white"
              textAlign="left"
              px={0}
              ml={-4}
              mt={1}
            >
              Researchers Around The Globe Are Putting Experimenter To The Test!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <FeaturedStudies />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Studies;
