import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKSocialButton from "components/MKSocialButton";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ScholarIcon from "@mui/icons-material/School";
import LanguageIcon from "@mui/icons-material/Language";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

function ExecutiveMemberCard({
  image,
  name,
  username,
  review,
  twitter,
  website,
  linkedin,
  scholar,
}) {
  return (
    <Card>
      <MKBox display="grid" justifyContent="center" position="relative" mt={-7}>
        <MKAvatar
          src={image}
          alt={name}
          size="xxl"
          shadow="lg"
          sx={{ position: "relative", zIndex: 2 }}
        />
        <MKBox
          width="6.875rem"
          height="6.875rem"
          borderRadius="50%"
          position="absolute"
          top="3.5%"
          left="50%"
          zIndex={1}
          sx={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            transform: "translateX(-50%) scale(0.87)",
            filter: "blur(12px)",
          }}
        />
      </MKBox>
      <MKBox pt={3} px={3} textAlign="center">
        <MKTypography variant="h4" fontWeight="bold">
          {name}
        </MKTypography>
        {username && (
          <MKTypography variant="body2" color="text" mb={2}>
            {username}
          </MKTypography>
        )}
        <MKTypography variant="body2" color="text" mt={1} mb={2}>
          {review}
        </MKTypography>
      </MKBox>
      <MKBox textAlign="center" pt={1} pb={3}>
        <Stack direction="row" spacing={2} justifyContent="center">
          {linkedin && (
            <a href={linkedin} target="_blank">
              <MKSocialButton color="linkedin" iconOnly circular>
                <LinkedInIcon />
              </MKSocialButton>
            </a>
          )}
          {twitter && (
            <a href={twitter} target="_blank">
              <MKSocialButton color="twitter" iconOnly circular>
                <TwitterIcon />
              </MKSocialButton>
            </a>
          )}
          {website && (
            <a href={website} target="_blank">
              <MKSocialButton color="website" iconOnly circular>
                <LanguageIcon />
              </MKSocialButton>
            </a>
          )}
          {scholar && (
            <a href={scholar} target="_blank">
              <MKSocialButton color="vimeo" iconOnly circular>
                <ScholarIcon />
              </MKSocialButton>
            </a>
          )}
        </Stack>
      </MKBox>
    </Card>
  );
}

// Setting default values for the props of SimpleReviewCard
ExecutiveMemberCard.defaultProps = {
  username: "",
};

// Typechecking props for the SimpleReviewCard
ExecutiveMemberCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  username: PropTypes.string,
  review: PropTypes.string.isRequired,
};

export default ExecutiveMemberCard;
