/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MKTypography from "components/MKTypography";

import desktopIcon from "assets/images/Participant/desktop.png";
import mobileIcon from "assets/images/Participant/mobile.png";
import vrIcon from "assets/images/Participant/vr.png";

import KeyFeatureCard from "pages/Experimenter/components/cards/KeyFeature";

// Otis Kit PRO components
import MKBox from "components/MKBox";

// Otis Kit PRO examples
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

function KeyFeatures() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          justifyContent={"center"}
          container
          item
          xs={12}
          lg={10}
          sx={{ mx: "auto" }}
        >
          <Grid item xs={12} md={4}>
            <SimpleInfoCard
              icon={
                <img
                  src={desktopIcon}
                  alt="experimenter"
                  height={125}
                  width={125}
                />
              }
              direction="center"
              title="Desktop"
              description="Host desktop experiments locally or online"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SimpleInfoCard
              icon={
                <img
                  src={mobileIcon}
                  alt="experimenter"
                  height={125}
                  width={125}
                />
              }
              direction="center"
              title="Mobile"
              description="Host your tasks on our mobile app - available for iOS and Android"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <SimpleInfoCard
              icon={
                <img src={vrIcon} alt="experimenter" height={125} width={125} />
              }
              direction="center"
              title="Virtual Reality"
              description="Export tasks to VR via the Oculus Quest Store "
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default KeyFeatures;
