import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";

// import Icon from "@mui/material/Icon";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import TeamCard from "pages/SilicoLabs/components/cards/Team";

// Images
import Executive from "pages/SilicoLabs/sections/Executive";
import Advisory from "pages/SilicoLabs/sections/Advisory";

function Team() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("executive");

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      py={{ xs: 0, lg: 2 }}
      px={{ xs: 0, lg: 0 }}
      mx={{ xs: 0, lg: 0 }}
      // 1
      shadow="xxl"
      width="100%"
      mt={-4}
    >
      <Container sx={{ maxWidth: "100% !important" }}>
        <Container sx={{ pb: { xs: 12, lg: 22 }, pt: 5 }}>
          <Grid
            container
            item
            flexDirection="column"
            alignItems="center"
            xs={12}
            md={8}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography variant="h2" color="white" mb={1}>
              Meet Our Team and Advisors
            </MKTypography>
            <MKTypography variant="body1" color="white" fontWeight="light">
              We're on a mission to make cutting-edge technology accessible to
              all
            </MKTypography>
          </Grid>
        </Container>
        <MKBox mt={-16}>
          <Container>
            <Grid container sx={{ mb: 6 }}>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                pt={{ xs: 8, sm: 6, lg: 0 }}
                sx={{ mx: "auto", textAlign: "center" }}
              >
                <AppBar position="static">
                  <Tabs value={activeTab} onChange={handleTabType}>
                    <Tab
                      id="executive"
                      label={
                        <MKBox py={0.5} px={0} color="inherit">
                          Team
                        </MKBox>
                      }
                    />
                    <Tab
                      id="advisory"
                      label={
                        <MKBox py={0.5} px={2} color="inherit">
                          Advisors
                        </MKBox>
                      }
                    />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
            <MKBox position="relative" zIndex={10} px={{ xs: 0, sm: 0 }}>
              <Grid container spacing={3} justifyContent="center">
                {tabType === "executive" ? <Executive /> : <Advisory />}
              </Grid>
            </MKBox>
          </Container>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Team;
