import { useRef } from "react";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";

// SwiperJS react components
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

// @mui material components
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Testimonial from "pages/SilicoLabs/components/cards/Testimonial";
import testimonialData from "data/testimonials";
import Grid from "@mui/material/Grid";

// Images
// import review1 from "assets/images/examples/BlakeRichards.jpg";
// import review2 from "assets/images/examples/AlexClarke.jpg";
// import review3 from "assets/images/examples/MorrisMoscovitch.png";
// import logoMcGil from "assets/images/logos/small-logos/logo-McGill.svg";
// import logoCambridge from "assets/images/logos/small-logos/logo-Cambridge.png";
// import logoUofT from "assets/images/logos/small-logos/logo-UofT.svg";
// import { validate } from "uuid";

function Information() {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  // Swiper navigation buttons styles
  const navigationStyles = {
    position: "absolute",
    top: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
    height: "100%",
    textAlign: "center",
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          width="100%"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h1" mb={2}>
            User Testimonials
          </MKTypography>
        </Grid>
        <Swiper
          onInit={({ params, navigation }) => {
            const { navigation: nav } = params;
            nav.prevEl = navigationPrevRef.current;
            nav.nextEl = navigationNextRef.current;
            navigation.init();
            navigation.update();
          }}
          autoplay={{ delay: 5000 }}
          speed={800}
          spaceBetween={0}
          slidesPerGroup={1}
          breakpoints={{
            995: { slidesPerView: 2 },
            1000: { slidesPerView: 2 },
          }}
          loop
        >
          {testimonialData.map((testimonial) => (
            <SwiperSlide>
              <Container>
                <Testimonial
                  image={testimonial.image}
                  title={testimonial.title}
                  review={testimonial.review}
                  color="light"
                  author={{
                    logo: testimonial.logo,
                    name: testimonial.pi,
                    role: testimonial.role,
                  }}
                />
              </Container>
            </SwiperSlide>
          ))}
          <Grid pt={6}>
            <MKBox
              display="flex"
              position="absolute"
              pt={5}
              height={40}
              bottom={{ xs: 0, lg: 0 }}
              left="5%"
              zIndex={2}
            >
              <MKTypography
                variant="h2"
                ref={navigationPrevRef}
                color="dark"
                mr={{ xs: 8, md: 2, lg: 8 }}
                sx={navigationStyles}
              >
                <MKBox className="fas fa-chevron-left" />
              </MKTypography>
            </MKBox>
            <MKBox
              display="flex"
              position="absolute"
              pt={5}
              bottom={{ xs: 0, lg: 0 }}
              height={40}
              right="5%"
              zIndex={2}
            >
              <MKTypography
                variant="h2"
                ref={navigationNextRef}
                color="dark"
                sx={navigationStyles}
              >
                <MKBox className="fas fa-chevron-right" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Swiper>
      </Container>
    </MKBox>
  );
}

export default Information;
