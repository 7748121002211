import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function PanelInfo({ icon, content }) {
  return (
    <MKBox display="flex" alignItems="center" p={2}>
      {icon}

      {/* <MKBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="0rem"
        height="0rem"
        borderRadius="xl"
        variant="gradient"
        color="white"
        // bgColor={color}
        // coloredShadow={color}
      >
        {typeof icon === "string" ? <Icon fontSize="small">{icon}</Icon> : icon}
      </MKBox> */}

      <MKTypography variant="body2" color="text" pl={2}>
        {content}
      </MKTypography>
    </MKBox>
  );
}

// Typechecking props for the AboutUsOption
PanelInfo.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

export default PanelInfo;
