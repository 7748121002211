// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import StepCard from "pages/SilicoLabs/components/cards/Steps";
import step1 from "assets/images/SilicoLabs/Steps/Step1.jpg";
import step2 from "assets/images/SilicoLabs/Steps/Step2.jpg";
import step3 from "assets/images/SilicoLabs/Steps/Step3.jpg";
import ConsultingCard from "pages/SilicoLabs/components/cards/Consulting";

import StepsCard from "pages/SilicoLabs/components/cards/Steps";

function Steps() {
  return (
    <MKBox position="relative" py={0} mx={2}>
      <Grid
        container
        item
        xs={12}
        lg={6}
        justifyContent="center"
        width="100%"
        sx={{ mx: "auto", textAlign: "center" }}
      >
        <MKTypography variant="h2" mb={2}>
          Real-World Behaviour, Real-World Insights
        </MKTypography>
        <Grid container spacing={2} justifyContent="center">
          <MKTypography variant="body1" color="text" mb={2}>
            Create virtual experiences that simulate real-world scenarios to
            train/assess humans & AI behaviour and save precious time and
            resources
          </MKTypography>
        </Grid>
      </Grid>
      <Grid container spacing={0} mt={4}>
        <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
          <StepsCard
            image={step1}
            title="Step 1 - Design Experiences"
            color="silicoBlue"
            description="Easily create custom interactive virtual experiences using your own custom images, video, audio, and 3D models. No code required."
            action={{
              type: "internal",
              route: "/experimenter",
              color: "silicoBlue",
              label: "Learn More",
            }}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
          <StepsCard
            image={step2}
            title="Step 2 - Capture Behaviour"
            color="silicoRed"
            description="Present experiences on desktop, mobile, or VR/AR headsets to capture human behaviour or use experiences to train/test AI systems."
            action={{
              type: "internal",
              route: "/participant",
              color: "silicoRed",
              label: "Learn More",
            }}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={4} mb={{ xs: 3, lg: 0 }}>
          <StepsCard
            image={step3}
            title="Step 3 - Utilize Data"
            color="silicoPurple"
            description="Utilize the behavioural data from experiences to gain quantative insights, assess individual performance, or to train AI to behave more like humans."
            action={{
              type: "internal",
              route: "/",
              color: "silicoPurple",
              label: null,
            }}
          />
        </Grid>
      </Grid>
    </MKBox>
  );
}

export default Steps;
