// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import augmented_reality from "assets/images/Experimenter/MixedReality/augmented_reality_experiment.png";

// Coworking page component
// import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

function AR() {
  return (
    <>
      <Container justifyContent="center" alignItems="center">
        <MKBox
          component="img"
          alignItems="center"
          src={augmented_reality}
          alt="image"
          mx="auto"
          pb={5}
          width={{ xs: "100%", lg: "80%" }}
          borderRadius="lg"
          shadow="xl"
          // mt={-24}
          display={{ xs: "block", lg: "block" }}
        />
      </Container>
      {/* <Container>
        <Grid container alignItems="center">
          <MKTypography variant="h3" color="white" my={1}>
            Virtual Reality
          </MKTypography>
          <MKTypography variant="body2" color="text" mb={2}>
            Pain is what we go through as we become older. We get insulted by
            others, lose trust for those others. We get back stabbed by friends.
            It becomes harder for us to give others a hand.
          </MKTypography>
          <MKTypography
            component="a"
            href="#"
            variant="body2"
            color="info"
            fontWeight="regular"
            sx={{
              width: "max-content",
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: "translateX(3px)",
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: "translateX(6px)",
              },
            }}
          >
            Get Started
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </Grid>
      </Container> */}
    </>
  );
}

export default AR;
