// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import ConsultingCard from "pages/SilicoLabs/components/cards/Consulting";
import collaboration from "assets/images/SilicoLabs/Consulting/collaboration.jpg";
import realworld from "assets/images/SilicoLabs/Consulting/realworld.jpg";
import science from "assets/images/SilicoLabs/Consulting/science.jpg";

function Consulting() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          flexDirection="column"
          textAlign="center"
          mx="auto"
          mb={0}
        >
          <MKTypography variant="h3">We Are Here To Help</MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Collaborations and Consulting Services
          </MKTypography>
        </Grid>
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12} md={6} lg={4}>
            <ConsultingCard
              color="dark"
              image={collaboration}
              label="Academic Collaborations"
              title="Push Boundaries"
              description="Got a big idea that requires developing new techniques? Work with us to bring your idea to life."
              action={{
                type: "internal",
                route: "/contact",
                label: "Contact Us",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ConsultingCard
              color="dark"
              image={realworld}
              label="Consulting Services"
              title="Gain Real-World Insights"
              description="Do you have a real-world problem that requires a real-world solution? We can help develop techniques that will provide the answers you need."
              action={{
                type: "internal",
                route: "/contact",
                label: "Contact Us",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ConsultingCard
              color="dark"
              image={science}
              label="Industry Research"
              title="Put Science To Work"
              description="Go beyond surveys and other self reporting techniques and utilize real-world behavioural techniques to unlock unprecedented predictive analytics."
              action={{
                type: "internal",
                route: "/contact",
                label: "Contact Us",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Consulting;
