// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Marquee from "react-fast-marquee";

// Images
import Mila from "assets/images/SilicoLabs/Accelerators/Logo-Mila.png";
import CDL from "assets/images/SilicoLabs/Accelerators/Logo-CDL.png";
import Mars from "assets/images/SilicoLabs/Accelerators/Logo-Mars.png";
import UTEST from "assets/images/SilicoLabs/Accelerators/Logo-UTEST.png";
import H2i from "assets/images/SilicoLabs/Accelerators/Logo-H2i.png";

function UsedBy() {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          px={5}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h1">
            Fueled by Canada's Leading Accelerators
          </MKTypography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          py={10}
          lg={12}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <a
            href="https://creativedestructionlab.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              height={100}
              px={5}
              mt={5}
              src={CDL}
              alt="Creative Destruction Lab"
              opacity={1}
            />
          </a>
          <a
            href="https://www.marsdd.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              height={100}
              px={5}
              mt={5}
              src={Mars}
              alt="Mars"
              opacity={1}
            />
          </a>
          <a
            href="https://mila.quebec/en/industry/entrepreneurship-lab/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              height={100}
              src={Mila}
              alt="Mila"
              px={5}
              mt={5}
              opacity={1}
            />
          </a>
          <a
            href="https://h2i.utoronto.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              height={100}
              px={5}
              mt={5}
              src={H2i}
              alt="H2i"
              opacity={1}
            />
          </a>
          <a
            href="https://h2i.utoronto.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MKBox
              component="img"
              height={100}
              px={5}
              mt={5}
              src={UTEST}
              alt="UTEST"
              opacity={1}
            />
          </a>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default UsedBy;
