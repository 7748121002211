/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MKTypography from "components/MKTypography";

import nocodeIcon from "assets/images/SilicoLabs/Solutions/icon-nocode.svg";
import TwoDIcon from "assets/images/SilicoLabs/Solutions/icon-2D3D.svg";
import bioIcon from "assets/images/SilicoLabs/Solutions/icon-bio.svg";
import aiIcon from "assets/images/SilicoLabs/Solutions/icon-ai.svg";

import KeyFeatureCard from "pages/Experimenter/components/cards/KeyFeature";

// Otis Kit PRO components
import MKBox from "components/MKBox";

// Otis Kit PRO examples
import SimpleInfoCard from "components/Cards/InfoCards/SimpleInfoCard";

function KeyFeatures() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={10} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={3}>
            <SimpleInfoCard
              icon={
                <img
                  src={nocodeIcon}
                  alt="experimenter"
                  height={100}
                  width={100}
                />
              }
              direction="center"
              title="100% No-Code"
              description="Stop staring at code and tap into your creativity with real time task design."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SimpleInfoCard
              icon={
                <img
                  src={TwoDIcon}
                  alt="experimenter"
                  height={100}
                  width={100}
                />
              }
              direction="center"
              title="2D, 3D, or Both"
              description="From 2D stimulus presentation to 3D environments, we have you covered!"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SimpleInfoCard
              icon={
                <img
                  src={bioIcon}
                  alt="experimenter"
                  height={100}
                  width={100}
                />
              }
              direction="center"
              title="Biosensor Ready"
              description="Easily collect and sync data from a wide range of biosensor devices."
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SimpleInfoCard
              icon={
                <img src={aiIcon} alt="experimenter" height={100} width={100} />
              }
              direction="center"
              title="Artificial Intelligence"
              description="Model human behaviour and cognition with unprecedented comparative accuracy."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default KeyFeatures;
