import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import PricingCard from "pages/ExperimenterPricing/components/cards/Pricing";

function Pricing() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("monthly");

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  return (
    <MKBox component="section" variant="gradient" bgColor="dark" py={{ xs: 0, lg: 7 }}>
      <Container sx={{ pb: { xs: 12, lg: 22 }, pt: 12 }}>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          md={8}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h3" color="white" mb={0}>
            Cutting Edge Research Requires Cutting Edge Tools
          </MKTypography>
          <MKTypography variant="body2" color="white">
            We have plans for every stage of your academic career
          </MKTypography>
        </Grid>
      </Container>
      <MKBox mt={-16}>
        <Container>
          <Grid container sx={{ mb: 6 }}>
            <Grid item xs={7} md={6} lg={4} sx={{ mx: "auto", textAlign: "center" }}>
              <AppBar position="static">
                <Tabs value={activeTab} onChange={handleTabType}>
                  <Tab
                    id="monthly"
                    label={
                      <MKBox py={0.5} px={2} color="inherit">
                        Monthly
                      </MKBox>
                    }
                  />
                  <Tab
                    id="annual"
                    label={
                      <MKBox py={0.5} px={2} color="inherit">
                        Annual
                      </MKBox>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>
          <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={4}>
                <PricingCard
                  badge={{ color: "light", label: "undergrad" }}
                  price={{ currency: "$", value: tabType === "annual" ? 59 : 99, type: "mo" }}
                  specifications={[
                    { label: "1 Licenses", includes: true },
                    { label: "Cloud storage (5G)", includes: true },
                    { label: "Artificial Intelligence", includes: true },
                    { label: "Desktop", includes: true },
                    { label: "Integration Support", includes: false },
                    { label: "Participant Credits", includes: false },
                    { label: "Web", includes: false },
                    { label: "Mobile", includes: false },
                    { label: "Virtual Reality", includes: false },
                  ]}
                  action={{
                    type: "internal",
                    route: "/",
                    color: "dark",
                    label: "Start Now!",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <PricingCard
                  color="dark"
                  badge={{ color: "info", label: "Primary Investigator" }}
                  price={{ currency: "$", value: tabType === "annual" ? 399 : 499, type: "mo" }}
                  specifications={[
                    { label: "3 Licenses", includes: true },
                    { label: "Cloud storage (100G)", includes: true },
                    { label: "Artificial Intelligence", includes: true },
                    { label: "Desktop", includes: true },
                    { label: "Integration Support", includes: true },
                    { label: "Participant Credits ($250)", includes: true },
                    { label: "Web", includes: true },
                    { label: "Mobile", includes: true },
                    { label: "Virtual Reality", includes: true },
                  ]}
                  action={{
                    type: "internal",
                    route: "/",
                    color: "silicoBlue",
                    label: "Start Now!",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <PricingCard
                  badge={{ color: "light", label: "grad student" }}
                  price={{ currency: "$", value: tabType === "annual" ? 99 : 199, type: "mo" }}
                  specifications={[
                    { label: "1 Licenses", includes: true },
                    { label: "Cloud storage (25G)", includes: true },
                    { label: "Artificial Intelligence", includes: true },
                    { label: "Desktop", includes: true },
                    { label: "Integration Support", includes: true },
                    { label: "Participant Credits ($50)", includes: true },
                    { label: "Web", includes: true },
                    { label: "Mobile", includes: false },
                    { label: "Virtual Reality", includes: false },
                  ]}
                  action={{
                    type: "internal",
                    route: "/",
                    color: "dark",
                    label: "Start Now!",
                  }}
                />
              </Grid>
            </Grid>
          </MKBox>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Pricing;
