// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// NavBar
import DefaultNavbar from "components/NavBar";

// Page Sections
import Steps from "pages/SilicoLabs/sections/Steps";
import UsedBy from "pages/SilicoLabs/sections/UsedBy";
import UserMarquee from "pages/SilicoLabs/sections/UserMarquee";
import Testimonials from "pages/SilicoLabs/sections/Testimonials";

import InfoExperimenter from "pages/SilicoLabs/sections/Experimenter";
import InfoParticipant from "pages/SilicoLabs/sections/Participant";
import InfoResearcher from "pages/SilicoLabs/sections/Researcher";
import TaskRotation from "pages/Experimenter/sections/TaskRotation";
import Divider from "@mui/material/Divider";

import Team from "pages/SilicoLabs/sections/Team";
import Consulting from "pages/SilicoLabs/sections/Consulting";
import MKButton from "components/MKButton";

import { Route } from "react-router-dom";
import { useEffect, useRef } from "react";
import * as Typed from "typed.js";

// Footer
import DefaultFooter from "components/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import heroImage from "assets/images/SilicoLabs/hero.png";

function Presentation() {
  const typedJSRef = useRef(null);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["2D", "3D", "VR", "AR", "XR"],
      typeSpeed: 150,
      backSpeed: 150,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={6}
            px={{ xs: 2, md: 5 }}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Create Interactive{" "}
              <span
                style={{ color: "rgba(241, 203, 104, 1)" }}
                ref={typedJSRef}
              />{" "}
              Experiences For Humans & AI
            </MKTypography>

            {/* <MKTypography
              variant="body1"
              color="white"
              mt={1}
              pr={{ md: 12, lg: 24, xl: 28 }}
              opacity={0.8}
            >
              Easily <b>Create</b>, <b>Deliver</b>, and <b>Share</b> interactive
              2D/3D/XR experiences to capture high-fidelity behavioural and
              biosensor data
            </MKTypography> */}
            <Grid
              container
              spacing={0}
              mt={4}
              justifyContent={{ xs: "center", md: "start" }}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <Grid item>
                <MKButton
                  mx={3}
                  variant="gradient"
                  style={{ color: "#5BADDC", fontSize: 15 }}
                  href={"/contact"}
                >
                  Early Access
                </MKButton>
              </Grid>
              <Grid item ml={1}>
                <MKButton
                  variant="gradient"
                  style={{ color: "#E37174", fontSize: 15 }}
                  href={
                    "https://www.youtube.com/channel/UCH3VYZcQ5iWgjYOtxr4SB2g"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Videos
                </MKButton>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={7}
              justifyContent="center"
              flexDirection="column"
            >
              <MKTypography variant="h5" color="white" mt={4} mb={-6}>
                Connect with us on
              </MKTypography>
              <br />
              <br />

              <MKBox alignItems="center">
                <MKTypography
                  component="a"
                  variant="body1"
                  color="white"
                  href="https://www.linkedin.com/company/silicolabs"
                  mr={3}
                >
                  <i className="fab fa-linkedin" />
                </MKTypography>
                <MKTypography
                  component="a"
                  variant="body1"
                  color="white"
                  href="https://twitter.com/SilicoLabs"
                  mr={3}
                >
                  <i className="fab fa-twitter" />
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Steps />
      </Card>
      <TaskRotation />
      <Divider sx={{ my: 0 }} />
      <UserMarquee />
      <Divider sx={{ my: 0 }} />
      <Testimonials />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 0,
          mb: 0,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <UsedBy />
      </Card>
      {/* <InfoExperimenter />
      <InfoParticipant /> */}
      <Team />
      <Consulting />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
