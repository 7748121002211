import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";

// import Icon from "@mui/material/Icon";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import VR from "pages/Experimenter/components/MixedReality/VR";
import AR from "pages/Experimenter/components/MixedReality/AR";
import VTR from "pages/Experimenter/components/MixedReality/VTR";

// Images
import Executive from "pages/SilicoLabs/sections/Executive";
import Advisory from "pages/SilicoLabs/sections/Advisory";

function MixedReality() {
  const [activeTab, setActiveTab] = useState(0);
  const [tabType, setTabType] = useState("vr");

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      py={{ xs: 0, lg: 7 }}
      px={{ xs: 0, lg: 7 }}
      mx={{ xs: -2, lg: -2 }}
      //   borderRadius="xxl"
      shadow="xxl"
      mt={5}
    >
      <Container alignItems="center">
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={6}
          pt={2}
        >
          <MKTypography variant="h2" color="white" mb={1}>
            Create Immersive Experiences
          </MKTypography>
          <MKTypography variant="body1" color="white" fontWeight="light">
            Plug-and-play virtual and augmented reality
          </MKTypography>
        </Grid>

        <MKBox mt={{ sm: -12, lg: -2 }}>
          <Container>
            <Grid container sx={{ mb: 6 }}>
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                pt={{ xs: 0, sm: 6, lg: 0 }}
                sx={{ mx: "auto", textAlign: "center" }}
              >
                <AppBar position="static">
                  <Tabs value={activeTab} onChange={handleTabType}>
                    <Tab
                      id="vr"
                      label={
                        <MKBox py={0.5} px={0} color="inherit">
                          VR
                        </MKBox>
                      }
                    />
                    <Tab
                      id="vtr"
                      label={
                        <MKBox py={0.5} px={0} color="inherit">
                          VR Testing Rooms
                        </MKBox>
                      }
                    />
                    <Tab
                      id="ar"
                      label={
                        <MKBox py={0.5} px={0} color="inherit">
                          AR
                        </MKBox>
                      }
                    />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
            <MKBox
              justifyContent="center"
              alignItems="center"
              zIndex={10}
              px={{ xs: 0, sm: 0 }}
              width={{ xs: "100%", lg: "100%" }}
            >
              <Grid container spacing={0}>
                {
                  {
                    vr: <VR />,
                    vtr: <VTR />,
                    ar: <AR />,
                  }[tabType]
                }
              </Grid>
            </MKBox>
          </Container>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default MixedReality;
