// Images
import blake from "assets/images/SilicoLabs/Testimonials/Square_Blake.png";
import vicky from "assets/images/SilicoLabs/Testimonials/Square_Vicky.png";
import james from "assets/images/SilicoLabs/Testimonials/Square_James.png";
import charlize from "assets/images/SilicoLabs/Testimonials/Square_Charlize.png";
import dane from "assets/images/SilicoLabs/Testimonials/Square_Dane.png";
import zi from "assets/images/SilicoLabs/Testimonials/Square_Zimming.png";
import alex from "assets/images/SilicoLabs/Testimonials/Square_Alex.png";
import morris from "assets/images/SilicoLabs/Testimonials/Square_Morris.png";
import chen from "assets/images/SilicoLabs/Testimonials/Square_Chen.png";
import claudia from "assets/images/SilicoLabs/Testimonials/Square_Claudia.png";
import mcgill from "assets/images/SilicoLabs/Testimonials/logo-McGill.png";
import cambridge from "assets/images/SilicoLabs/Testimonials/logo-Cambridge.png";
import uoft from "assets/images/SilicoLabs/Testimonials/logo-UofT.png";
import mila from "assets/images/SilicoLabs/Testimonials/logo-Mila.png";
import ku from "assets/images/SilicoLabs/Testimonials/ku.png";

const data = [
  {
    id: 0,
    pi: "Blake Richards, PhD",
    role: "Assistant Professor, McGill University",
    image: blake,
    title: "Seamless comparison between human beings and AI!",
    review:
      "No other software allows for such seamless comparison between human beings and AI models in complex tasks. SilicoLabs makes it easy for academics and smaller companies to do the kinds of tests on AI typically only done by large tech companies.",
    logo: mcgill,
  },
  {
    id: 1,
    pi: "Morris Moscovitch, PhD",
    role: "Professor Emeritus, University of Toronto",
    image: morris,
    title: "The wave of the future!",
    review:
      "The ability to create 3D environment experiments quickly and easily, and place online for testing, is a boon for psychological research, and likely the wave of the future.",
    logo: uoft,
  },
  {
    id: 2,
    pi: "Alex Clarke, PhD",
    role: "Sir Henry Dale Fellow, Cambridge Univeristy",
    image: alex,
    title: "Experiment control in the real world!",
    review:
      "Using SilicoLabs' Experimenter software allowed us to create a fully mobile augmented reality task and collect event related EEG data",
    logo: cambridge,
  },
  {
    id: 3,
    pi: "Claudia Damiano, PhD",
    role: "Postdoctoral Fellow, KU Leuven",
    image: claudia,
    title: "Get straight to doing science!",
    review:
      "As a researcher who is new to the VR scene, I struggled on my own for months to create a simple VR experiment. It seemed like my only option was to pay almost $10k for someone else to program it for me. With Experimenter, I was able to put it together in days (and that included learning how to use the software)! High flexibility and control, no struggling with code. This really is amazing software that I cannot recommend enough!",
    logo: ku,
  },
  {
    id: 4,
    pi: "Chen Sun, PhD",
    role: "Postdoctoral Fellow, Mila",
    image: chen,
    title: "Test new algorithms that work way beyond toy tasks!",
    review:
      "Creating 3D (real-world-like) tasks for AI research is something usually reserved for big companies that can pour a lot of resources into the engineering. SilicoLabs endows us (little individual researchers) this ability. With Experimenter, creating 3D tasks becomes simple and intuitive, so that we can test new algorithms that work way beyond toy tasks.",
    logo: mila,
  },
  {
    id: 5,
    pi: "Victoria Nicholls, PhD",
    role: "Research Associate, University of Cambridge",
    image: vicky,
    title: "A game changer for real world neuroscience research!",
    review:
      "Experimenter’s intuitive point-and-click interface provides the flexibility necessary to accommodate a large variety of experiment designs. Using Experimenter, I was able to quickly and easily create a real-world augmented reality and mobile EEG task. This is a game changer, opening up the door for more researchers to conduct realistic neuroscience experiments.",
    logo: cambridge,
  },
  {
    id: 6,
    pi: "Ziming Cheng",
    role: "PhD Student, University of Toronto",
    image: zi,
    title: "Potential to revolutionize the memory field!",
    review:
      "Memory researchers have long been focused on tasks that require remembering lists of words or images. These lab-controlled, abstract tasks, have brought us a long way theoretically, but are often hard to apply to the real-world. Experimenter is an easy-to-learn tool for creating experiments in which the participants complete real-world tasks. This opens doors to more ecologically valid studies that have the potential to revolutionize memory theories and applications.",
    logo: uoft,
  },
  {
    id: 7,
    pi: "Charlize Linthorn",
    role: "Undergraduate Student, McGill University",
    image: charlize,
    title: "An intutive UI built for creative and complex task designs!",
    review:
      "Experimenter allowed me to create my first 3D task without needing an extensive coding background. It was very intuitive while still allowing for creativity and complexity!",
    logo: mcgill,
  },
  {
    id: 8,
    pi: "James Yuan",
    role: "Undergraduate Student, University of Toronto",
    image: james,
    title: "Beginner-friendly yet flexible!",
    review:
      "Experimenter was a relief to discover for creating my first 3D task. The process was beginner-friendly while providing as much flexibility as needed for an increasingly complex behavioural design.",
    logo: uoft,
  },
  {
    id: 9,
    pi: "Dane Malenfant",
    role: "Undergraduate Student, McGill University",
    image: dane,
    title: "A great tool for psychology education!",
    review:
      "I wish I had access to SilicoLab’s Experimenter software when taking undergraduate psychology courses! Its intuitive interface would have really aided my understanding of research methods and psychological experimentation. I look forward to seeing how Experimenter improves access to psychology education.",
    logo: mcgill,
  },
];

export default data;
