/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import DefaultNavbar from "components/NavBar";
import DefaultFooter from "components/Footers/DefaultFooter";

// Sections
import KeyFeatures from "pages/Participant/sections/KeyFeatures";
import PanelFlexibility from "pages/Participant/sections/PanelFlexibility";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import heroImage from "assets/images/Participant/hero.png";

function Participant() {
  return (
    <>
      <DefaultNavbar
        brand="SilicoLabs / Capture Behaviour"
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={6}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Run Tasks Anywhere, On Any Platform
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              mt={1}
              pr={{ md: 12, lg: 24, xl: 32 }}
              opacity={0.8}
            >
              Collecting data has never been easier!
            </MKTypography>
            <Grid
              container
              item
              xs={12}
              lg={7}
              justifyContent="center"
              flexDirection="column"
            >
              <MKTypography variant="h5" color="white" mt={4} mb={-6}>
                Connect with us on
              </MKTypography>
              <br />
              <br />

              <MKBox alignItems="center">
                <MKTypography
                  component="a"
                  variant="body1"
                  color="white"
                  href="https://www.facebook.com/SilicoLabs"
                  mr={3}
                >
                  <i className="fab fa-facebook" />
                </MKTypography>
                <MKTypography
                  component="a"
                  variant="body1"
                  color="white"
                  href="https://www.linkedin.com/company/silicolabs"
                  mr={3}
                >
                  <i className="fab fa-linkedin" />
                </MKTypography>
                <MKTypography
                  component="a"
                  variant="body1"
                  color="white"
                  href="https://twitter.com/SilicoLabs_"
                  mr={3}
                >
                  <i className="fab fa-twitter" />
                </MKTypography>
                <MKTypography
                  component="a"
                  variant="body1"
                  color="white"
                  href="https://www.youtube.com/channel/UCH3VYZcQ5iWgjYOtxr4SB2g"
                >
                  <i className="fab fa-youtube" />
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <KeyFeatures />
        <PanelFlexibility />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Participant;
