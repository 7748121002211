// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import rl_loop from "assets/images/Experimenter/rl_loop.png";

function ArtificialIntelligence() {
  return (
    <MKBox
      //   variant="gradient"
      bgColor="dark"
      position="relative"
      borderRadius="xl"
      mx={{ xs: 0, xl: 3, xxl: 20 }}
      mt={0}
      mb={0}
      py={2}
      px={0}
      sx={{ overflow: "hidden" }}
    >
      <MKBox
        component="img"
        src={bgPattern}
        alt="pattern-lines"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        opacity={0.6}
      />
      <Container justifyContent="center" alignItems="center">
        <MKBox
          component="img"
          // bgColor="dark"
          alignItems="center"
          src={rl_loop}
          alt="image"
          mx="auto"
          pb={0}
          width={{ xs: "100%", lg: "80%" }}
          borderRadius="lg"
          shadow="xl"
          // mt={-24}
          display={{ xs: "block", lg: "block" }}
        />
      </Container>
    </MKBox>
  );
}

export default ArtificialIntelligence;
