// @mui material components
import Icon from "@mui/material/Icon";
import Participant from "layouts/pages/participant";
import Experimenter from "layouts/pages/experimenter";
import FAQ from "layouts/pages/faq";
import ContactUs from "layouts/pages/contact";
import WaitList from "layouts/pages/waitlist";
import Timeline from "layouts/pages/timeline";
import FeaturedStudiesPage from "layouts/pages/featured-studies";
import News from "layouts/pages/news";

const routes = [
  {
    name: "Features",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Design Experiences",
        description: "Create 2D, 3D, & immersive Experiences",
        route: "/experimenter",
        component: <Experimenter />,
      },
      {
        name: "Capture Behaviour",
        description: "Deliver Experiences on any platform",
        route: "/participant",
        component: <Participant />,
      },
      // {
      //   name: "Utilize Data",
      //   description: "Gain real-world insights",
      //   href: "/",
      // },
    ],
  },
  // {
  //   name: "About",
  //   icon: <Icon>article</Icon>,
  //   collapse: [
  //     {
  //       name: "Vision",
  //       description: "Anyone can be scientist with the right tools",
  //       href: "https://www.creative-tim.com/learning-lab/material-ui-marketplace/quick-start/otis-kit/",
  //     },
  //     {
  //       name: "Team",
  //       description: "Meet our team of innovators",
  //       href: "/#team",
  //       component: "#",
  //       route: "/#team",
  //       // component: <SilicoLabs />,
  //     },
  //     {
  //       name: "Partners",
  //       description: "We get by with a little help from our friends",
  //       href: "/",
  //     },
  //   ],
  // },
  {
    name: "News & Updates",
    icon: <Icon>article</Icon>,
    href: "https://www.news.silicolabs.ca",
    // collapse: [
    //   // {
    //   //   name: "Timeline",
    //   //   description: "Get the Play-by-play",
    //   //   route: "/timeline",
    //   //   component: <Timeline />,
    //   // },
    //   {
    //     name: "",
    //     description: "Stay up-to-date",
    //     href: "https://www.news.silicolabs.ca",
    //     // component: <News />,
    //   },
    //   // {
    //   //   name: "Case Studies",
    //   //   description: "Used by leading scientists",
    //   //   route: "/experimenter-examples",
    //   //   component: <FeaturedStudiesPage />,
    //   // },
    // ],
  },
  {
    name: "Q&A",
    icon: <Icon>article</Icon>,
    collapse: [
      // {
      //   name: "FAQ",
      //   description: "Got Questions? We've got Answers",
      //   route: "/faq",
      //   component: <FAQ />,
      // },
      {
        name: "Contact Us",
        description: "We're here to help!",
        route: "/contact",
        component: <ContactUs />,
      },
      {
        name: "Early Access",
        description: "Be one of the first!",
        route: "/waitlist",
        component: <WaitList />,
      },
    ],
  },
];

export default routes;
